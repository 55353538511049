import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';

import Link from '@nib-components/link';
import Form from '@nib-components/form';
import Copy from '@nib-components/copy';
import Modal from '@nib-components/modal';
import { PrimaryButton } from '@nib-components/button';
import { Section, Stack } from '@nib/layout';

import HeroPanel from '../../components/HeroPanel';
import AgreeAndSubmitFormSection, {
  validateConditionsAccepted,
} from '../../components/nwo-form/AgreeAndSubmitFormSection';
import ReferralNameFormSection from '../../components/nwo-form/ReferralNameFormSection';
import NonMemberDetailsFormSection, {
  validateNonMembers,
} from '../../components/nwo-form/NonMemberDetailsFormSection';
import ChildrenDetailsFormSection, {
  validateChildren,
} from '../../components/nwo-form/ChildrenDetailsFormSection';
import AddressFormSection, {
  validateAddressDetails,
} from '../../components/nwo-form/AddressFormSection';
import ContactDetailsFormSection, {
  validateContactDetails,
} from '../../components/nwo-form/ContactDetailsFormSection';
import PersonalDetailsFormSection, {
  validatePersonalDetails,
} from '../../components/nwo-form/PersonalDetailsFormSection';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';

import desktop from '../../img/ngatiwhatuaorakei/enrol-hero/desktop.jpg';
import desktop2x from '../../img/ngatiwhatuaorakei/enrol-hero/desktop@2x.jpg';
import mobile from '../../img/ngatiwhatuaorakei/enrol-hero/mobile.jpg';
import mobile2x from '../../img/ngatiwhatuaorakei/enrol-hero/mobile@2x.jpg';
import tablet from '../../img/ngatiwhatuaorakei/enrol-hero/tablet.jpg';
import tablet2x from '../../img/ngatiwhatuaorakei/enrol-hero/tablet@2x.jpg';
import { postContactUsEmail } from '../../components/shared/ContactUsApiService';
import { generateNwoEmailBody } from '../../components/shared/contactUsContent/memberEnrolmentEmail';
import { HeroPanelCopy } from '../../components/styledComponents';

export const EnrolPageQuery = graphql`
  query EnrolPageQuery {
    policy: allContentfulDocument(filter: { name: { eq: "NWO Policy Document" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const title = 'Ngāti Whātua Ōrākei Enrolment';
const metaDescription = 'Enrol in the Ngāti Whātua Ōrākei health insurance scheme today.';

interface EnrolProps extends RouteComponentProps, EnrolDocumentProps {}

interface GraphQLEnrolDocumentNode {
  edges: ContentfulEnrolDocumentEdgeShape[];
}

interface ContentfulEnrolDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface EnrolDocumentProps {
  data: {
    policy: GraphQLEnrolDocumentNode;
  };
}

const NWOEnrol = (props: EnrolProps): JSX.Element => {
  const policyDocument = props?.data?.policy?.edges?.[0]?.node?.document;
  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <HeroPanelSection />
        <FormDescriptionSection policyDocument={policyDocument} />
        <FormikSection />
      </div>
    </Layout>
  );
};

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="Ngāti Whātua Ōrākei"
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    >
      <HeroPanelCopy>Health Insurance Enrolment</HeroPanelCopy>
      <PrimaryButton href="/ngatiwhatuaorakei">Learn more</PrimaryButton>
    </HeroPanel>
  );
};

interface FormDescriptionSectionProps {
  policyDocument: ContentfulDocument;
}

const FormDescriptionSection = ({ policyDocument }: FormDescriptionSectionProps): JSX.Element => {
  return (
    <Section>
      <Stack space={4}>
        <Copy measure={false}>
          Complete all sections of this form to enrol into the Ngāti Whātua Ōrākei Health Insurance.
          To be eligible, you must be a registered Ngāti Whātua Ōrākei hapū member living in New
          Zealand. Anyone 18 and over must complete a separate form.
        </Copy>
        <Copy measure={false}>
          The registration process occurs once annually. Applications are processed during April-May
          and your policy will start on 4 July, with the welcome pack received in June.
        </Copy>
        <Copy measure={false}>
          Registered and eligible Ngāti Whātua Ōrākei hapū members will be covered under the{' '}
          <Link href={policyDocument?.file?.url} target="_blank">
            Ngāti Whātua Ōrākei Health Insurance
          </Link>
          . Any additional cover for yourself or any cover for non-member whānau will be at your own
          cost. For any assistance, free phone the friendly nib team on 0800 nib nwo (0800 642 696).
        </Copy>
      </Stack>
    </Section>
  );
};

export interface BasePersonDetails {
  fullName: string;
  dateOfBirth: string;
  gender: string;
}

export interface ChildDetails extends BasePersonDetails {
  isOfAge: boolean;
}

export const addEmptyChildDetails = (): ChildDetails => {
  return {
    fullName: '',
    dateOfBirth: '',
    gender: '',
    isOfAge: false,
  };
};

export const addNonMemberDetails = (): BasePersonDetails => {
  return {
    fullName: '',
    dateOfBirth: '',
    gender: '',
  };
};

interface EnrolmentFormValues {
  title: string;
  firstName: string;
  middleName: string;
  surname: string;
  dateOfBirth: string;
  gender: string;
  preferredGender: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  email: string;
  numberAndStreet: string;
  suburb: string;
  city: string;
  country: string;
  postCode: string;
  autoFillAddress: string;
  childrenDetails: ChildDetails[];
  nonMemberDetails: BasePersonDetails[];
  referralName: string;
  privacyAccepted: boolean;
  consentAccepted: boolean;
}

const getInitialValues = (): EnrolmentFormValues => {
  return {
    title: '',
    firstName: '',
    middleName: '',
    surname: '',
    dateOfBirth: '',
    gender: '',
    preferredGender: '',
    homePhone: '',
    mobilePhone: '',
    workPhone: '',
    email: '',
    numberAndStreet: '',
    suburb: '',
    city: '',
    country: '',
    postCode: '',
    autoFillAddress: '',
    childrenDetails: [addEmptyChildDetails()],
    nonMemberDetails: [addNonMemberDetails()],
    referralName: '',
    privacyAccepted: false,
    consentAccepted: false,
  };
};

const onSubmit = (values, { setSubmitting, setStatus, resetForm }, setShowSuccessModal) => {
  const commType = 'ngatiwhatuaorakei';
  const commDescription = 'Member enrolment - nib';
  postContactUsEmail(commType, commDescription, '', '', [], generateNwoEmailBody(values))
    .then(function () {
      resetForm({});
      setStatus({
        submitSucceeded: true,
      });
      // Need to pass this function in so that you can set the state
      setShowSuccessModal(true);
    })
    .catch(function (error) {
      setStatus({
        submitSucceeded: false,
        errorMessage: error.message,
      });
    });
};

const validate = (values) => {
  const errors: any = {};
  validatePersonalDetails(errors, values);
  validateContactDetails(errors, values);
  validateAddressDetails(errors, values);
  validateConditionsAccepted(errors, values);
  validateChildren(errors, values.childrenDetails);
  validateNonMembers(errors, values.nonMemberDetails);
  return errors;
};

const FormikSection = (): JSX.Element => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <div>
      <Modal title="Great!" visible={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <div>
          Once registration is confirmed, you will be enrolled into the Ngāti Whātua Ōrākei Health
          Insurance plan, and will receive your welcome pack including your Acceptance certificate
          and Policy document. This process occurs once annually. Applications are processed during
          April-May and your policy will start on 4 July, with the welcome pack received in June.
        </div>
      </Modal>
      <Section background="sunsetPink60">
        <Formik
          initialValues={getInitialValues()}
          validate={validate}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            onSubmit(values, { ...formikHelpers }, setShowSuccessModal)
          }
          component={EnrolmentFormInner}
        />
      </Section>
    </div>
  );
};

export const sharedColumnSpace = { xs: 2, xl: 7 } as const;

export interface BaseFormikProps extends FormikProps<EnrolmentFormValues> {}

interface EnrolmentFormInnerProps extends BaseFormikProps {}

const EnrolmentFormInner = (props: EnrolmentFormInnerProps): JSX.Element => {
  return (
    <Form
      id="enrolmentForm"
      name="enrolmentForm"
      containerWidth={null}
      onSubmit={props.handleSubmit}
      style={{ backgroundColor: 'rgba(0,0,0,0)' }}
    >
      <PersonalDetailsFormSection {...props} />
      <ContactDetailsFormSection {...props} />
      <AddressFormSection {...props} />
      <ChildrenDetailsFormSection {...props} />
      <NonMemberDetailsFormSection {...props} />
      <ReferralNameFormSection {...props} />
      <AgreeAndSubmitFormSection {...props} />
    </Form>
  );
};

export default metrics({ pageName: 'nwo' })(NWOEnrol);
