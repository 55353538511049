import React from 'react';

import Container from '@nib-components/container';
import Divider from '@nib-components/divider';
import { Stack } from '@nib/layout';
import { PrimaryButton } from '@nib-components/button';
import Link from '@nib-components/link';
import Alert from '@nib-components/alert';
import { CommErrorMessage } from '../shared/ContactUsApiCommon';

import { BaseFormikProps } from '../../pages/ngatiwhatuaorakei/enrol';
import CheckboxFormControl from './controls/CheckboxFormControl';
import { scrollToError } from '../../utils/form-validation';

interface AgreeAndSubmitFormSectionProps extends BaseFormikProps {}

const AgreeAndSubmitFormSection = (props: AgreeAndSubmitFormSectionProps) => {
  const { touched, values, isSubmitting, status, errors } = props;
  const { privacyAccepted, consentAccepted } = values;

  return (
    <Container>
      <Stack space={4}>
        <Divider />
        <span>
          <strong>Consent to sharing</strong>
          <br />
          To be a part of the Ngāti Whātua Ōrākei Health Insurance and to complete the enrolment
          process, we need your consent for:
          <ul>
            <li>
              nib to share some of your personal information with Ngāti Whātua Ōrākei and its
              related entities(e.g. your name, contact details, high level details about the claims
              you have made, the level of engagement with the kaiarahi and your frequency of claims,
              any suspicious activity and if there is a cancellation of your policy) to enable
              specific Ngāti Whātua Ōrākei personnel to encourage engagement with the kaiarahi,
              refer you to relevant programs, ensure you are making the most out of your cover, and
              any other related purposes as agreed between nib and Ngāti Whātua Ōrākei.
            </li>
            <li>
              Ngāti Whātua Ōrākei to share some of your information with nib (e.g. your name and
              contact details) to ensure your information is up to date and for nib to share
              communications with you.
            </li>
          </ul>
          If you do not provide your consent, we cannot enrol you in the Ngāti Whātua Ōrākei Health
          Insurance.
          <br />
          <br />
          <strong>Knowing your privacy rights</strong>
          <br />
          You have rights under the Privacy Act regarding your private information. At any time, you
          can access, check and correct, or ask for a copy of any information, nib or Ngāti Whātua
          Ōrākei have about you.
          <br />
          <br />
          For a copy of nib’s privacy policy, including our contact details if you want to access
          your personal information, please visit our Privacy policy webpage{' '}
          <Link href="http://nib.co.nz/privacy-policy" target="_blank">
            Privacy Policy | nib
          </Link>
          .
          <br />
        </span>
        <CheckboxFormControl
          id={`privacy-accepted`}
          value={privacyAccepted}
          fieldName="privacyAccepted"
          label={
            <span>
              <strong>Privacy</strong>
              <br />
              Yes I've read and agree to the{' '}
              <Link href="http://nib.co.nz/privacy-policy" target="_blank">
                privacy policy
              </Link>{' '}
              for myself and everyone on this enrolment form and I have authority to provide
              personal information about everyone on this form
            </span>
          }
          width="100%"
          isTouched={touched.privacyAccepted}
          valid={!errors.privacyAccepted}
          error={errors.privacyAccepted}
          {...props}
        />
        <CheckboxFormControl
          id={`consent-accepted`}
          value={consentAccepted}
          fieldName="consentAccepted"
          label={
            <span>
              <strong>Consent</strong>
              <br />
              Yes I give consent for nib and Ngāti Whātua Ōrākei to share relevant information about
              me, I’ve read and agree to the privacy policy for myself and everyone on this
              enrolment form and I have authority to provide personal information about everyone on
              this form.
            </span>
          }
          width="100%"
          isTouched={touched.consentAccepted}
          valid={!errors.consentAccepted}
          error={errors.consentAccepted}
          {...props}
        />
        {status && !status.submitSucceeded && status.errorMessage && (
          <Alert type="error" title="Oops, something went wrong" fullWidth={false}>
            <CommErrorMessage />
          </Alert>
        )}
        <PrimaryButton type="submit" disabled={isSubmitting} onClick={() => scrollToError()}>
          Submit form
        </PrimaryButton>
      </Stack>
    </Container>
  );
};

export const validateConditionsAccepted = (errors, values) => {
  if (!values.privacyAccepted) {
    errors.privacyAccepted = 'You must agree to the privacy policy';
  }
  if (!values.consentAccepted) {
    errors.consentAccepted = 'You must give consent for information sharing';
  }
};

export default AgreeAndSubmitFormSection;
